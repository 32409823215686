<template>
    <div class="search-container" @click="onContainerClick">
        <ui-input id="searchAccount" :clearOptions="true" name="searchAccount" label="Search accounts" type="text" placeholder="Search account"
            v-model:value="searchTerm" @mouseover="isListVisible = true" autocomplete="off" @clearSearch="handleSearchCancel"/>
        <ul v-if="isListVisible && searchResults.length > 0" class="results-list" @mouseleave="hideResults()" @mouseover="isListVisible = true">
            <li v-if="account" :key="0" class="result-item">
                <button class="result-button" @click="handleAccountSelection({id: 0, first_name: 'All', last_name: 'accounts'})">
                    <span class="font-weight-600">All accounts</span>
                </button>
            </li>
            <li v-for="result in searchResults" :key="result.id" class="result-item">
                <button class="result-button" @click="handleAccountSelection(result)">
                    <div>
                        <li><span class="font-weight-600">{{ result.first_name }} {{ result.last_name || '' }}</span></li>
                        <li><span>{{ result.email }}</span></li>
                    </div>
                    <ul class="list-element-right">
                        <li v-if="result.name"><span>{{ result.name }}</span></li>
                    </ul>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import UiInput from '@/components/ui/Input';
import axios from 'axios';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    components: { UiInput },
    data() {
        return {
            searchTerm: '',
            searchResults: [],
            isLoading: false,
            error: null,
            cancelTokenSource: null,
            isListVisible: false,
            isSelecting: true
        };
    },
    computed: {
        ...mapGetters({
            account: 'globalFilters/account',
        }),
        ...mapState('globalFilters', ['account']),
        selectAccount: {
            get: function () {
                return this.account;
            },
            set: function (newValue) {
                this.setAccount(newValue || 0);
            },
        },
    },
    methods: {
        ...mapActions({ setAccount: 'globalFilters/setAccount' }),
        async fetchData() {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('Operation canceled due to new request.');
            }
            this.cancelTokenSource = axios.CancelToken.source();

            this.isLoading = true;
            this.error = null;
            try {
                const { data } = await this.$axios.get(`/_/accounts/search?q=${this.searchTerm}`, {
                    cancelToken: this.cancelTokenSource.token
                });
                this.searchResults = data;
                this.isListVisible = true;
            } catch (error) {
                this.searchResults = [];
            } finally {
                this.isLoading = false;
            }
        },
        debouncedFetchData: debounce(function () {
            this.fetchData();
        }, 300),
        hideResults() {
            this.isListVisible = false;
        },
        onContainerClick(event) {
            event.stopPropagation();
        },
        handleAccountSelection(account) {
            if (account.id === 0) {
                this.setAccount(0); 
                this.hideResults();
                this.searchTerm = '';
                this.searchResults = [];
            } else {
                this.setAccount(account);
                this.searchTerm = `${account.first_name} ${account.last_name || ''}`;
                this.isListVisible = false;
            }
            this.isSelecting = false;
        },
        handleSearchCancel() {
            this.setAccount(0); 
            this.searchTerm = '';
            this.hideResults();
        }
    },
    watch: {
        searchTerm() {
            if (!this.searchTerm) {
                this.setAccount(0); 
                this.hideResults();
            }

            if (this.isSelecting) {
                this.debouncedFetchData();
            } else {
                this.isSelecting = true;
            }
        },
        account(newAccount) {
            if (!newAccount || newAccount === 0) {
                this.selectAccount = null;
                this.searchTerm = '';
                this.isSelecting = false;
            } else {
                this.selectAccount = newAccount;
                this.isListVisible = false;
                this.searchResults = [];
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.hideResults);
        if (this.account && this.account !== 0) {
            this.isSelecting = false;
            this.searchTerm = `${this.account.first_name} ${this.account.last_name || ''}`;
        }
    },
};
</script>

<style scoped>
.search-container {
    position: relative;
    width: 400px;
}

.results-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 10;
}

.result-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none;
    background-color: transparent;
    text-align: left;
    padding: 10px;
}

.result-item {
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.list-element {
    list-style: none;
}

.list-element-right {
    list-style: none;
    text-align: right;
}

.result-item:hover,
.result-button:active,
.result-item:active {
    background-color: #bebebe;
    font-weight: bold;
}

.result-button:active,
.result-item:active {
    background-color: #71eb7b;
    color: white;
}

.result-item:last-child {
    border-bottom: none;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
